import React, { useEffect, useRef } from "react";
import SafeWorkplaceDashboard from "./components/SafeWorkplacePackagesGraphic";

const App: React.FC = () => {
  const appRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const sendHeightToParent = () => {
      if (appRef.current) {
        const height = appRef.current.scrollHeight;
        window.parent.postMessage({ type: "setHeight", height }, "*");
      }
    };

    // Send height after initial render
    sendHeightToParent();

    // Send height after any window resize
    window.addEventListener("resize", sendHeightToParent);

    // Optional: Set up a MutationObserver to detect content changes
    const observer = new MutationObserver(sendHeightToParent);
    if (appRef.current) {
      observer.observe(appRef.current, { childList: true, subtree: true });
    }

    return () => {
      window.removeEventListener("resize", sendHeightToParent);
      observer.disconnect();
    };
  }, []);

  return (
    <div className="App" ref={appRef}>
      <SafeWorkplaceDashboard />
    </div>
  );
};

export default App;
